<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-600px">
            <div>
                <p class="title">[{{ pop.info.title }}] 이수자 리스트</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-30">
                <button type="button" class="btn-default float-right" @click="pop.downExcel">Excel 다운로드</button>
                <div class="clear"></div>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-list">
                    <colgroup>
                        <col width="60">
                        <col width="140">
                        <col width="140">
                        <col width="*">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>본부</th>
                            <th>팀</th>
                            <th>이름</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in pop.list" :key="i">
                            <td>{{ irow.num }}</td>
                            <td>{{ irow.office_name }}</td>
                            <td>{{ irow.team_name }}</td>
                            <td><router-link :to="{ name:'myPIMS-PersonalInfoList_tab5-idx', params:{idx:irow.idx} }" target="_blank"><span class="btn-view">{{ irow.ename }}({{ irow.kname }})</span></router-link></td>
                        </tr>
                        <tr v-if="pop.total==0">
                            <td colspan="4">이수자가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'EducationApplicantListPopup',
    components: {
    },
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        }
    },
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            idx : 0,
            info : { title:"교육" },
            list : [], total : 0,

            downExcel : () => {
                location.href = "https://intranet.mxm.kr/excel/education_applicant_list?idx=" + pop.idx;
            },

            doSearch : () => {
                let params = {
                    idx : pop.idx,
                };

                axios.get('/rest/mypims/getEducationInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info  = res.data;
                        pop.list  = res.data.list;
                        pop.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx    = props.idx;

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>