<template>
    <div class="con-wrap">
        <EducationApplicantListPopup :idx="pop.idx" v-if="pop.isShow" @close="pop.hideView"></EducationApplicantListPopup>

        <CarrotTitle title="전사 교육 관리">
            - 교육 시간을 부여할 수 있는 페이지입니다.
        </CarrotTitle>
        <div v-if="bbs.isAuth==true" class="board">
            <div class="mt-40">
                <table class="table-row table-serach">
                    <colgroup>
                        <col style="width:160px">
                        <col style="width:440px">
                        <col style="width:160px">
                        <col style="width:440px">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>교육기간</th>
                            <td>
                                <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inblock"></carrot-date-picker>
                                ~
                                <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inblock"></carrot-date-picker>
                            </td>
                            <th>교육명</th>
                            <td>
                                <input type="text" class="w-100per" v-model="bbs.stext">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn-default float-right mt-10" @click="bbs.doInit">검색</button>
                <div class="clear"></div>
            </div>
            <div class="mt-40 mb-20">
                <div>

                    <div class="mt-50 mb-20">
                        <router-link :to="{ name:'myPIMS-EducationAdd' }"><button class="btn-default float-right h-30">교육 시간 등록</button></router-link>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="400">
                            <col width="*">
                            <col width="150">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>교육기간</th>
                                <th>교육 명</th>
                                <th>이수자 목록</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.sdate }}{{ irow.edate?'~'+irow.edate:'' }}</td>
                                <td><span @click="bbs.showMod(irow.idx)" class="btn-view">{{ irow.title }}</span></td>
                                <td><span @click="pop.showView(irow.idx)" class="btn-view">View</span></td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="4">이수할 교육이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import EducationApplicantListPopup from '@/components/popup/myPIMS/EducationApplicantListPopup.vue'
import { useStore } from 'vuex';


export default {
    layout:"myPIMS",
    components: {
        EducationApplicantListPopup,
        CarrotDatePicker
    },
    setup() {
        const router = useRouter();
        const toast  = useToast();
        const store  = useStore();

        const pop = reactive({
            idx : 0,
            isShow : false,

            showView : (idx) => {
                pop.idx = idx;
                pop.isShow = true;
            },

            hideView : () => {
                bbs.doSearch();
                pop.isShow = false;
                pop.idx = 0;
            }
        });

        const bbs = reactive({
            isMounted : false,
            isAuth    : false,

            page : 1,
            rows : 20,

            sdate : "",
            edate : "",
            skind : "title",
            stext : "",

            list : [], total : 0,

            showMod : (idx) => {
                router.push({
                    name : 'myPIMS-EducationMod-idx',
                    params : { idx:idx }
                });
            },

            showAdd : () => {
                router.push({
                    name : 'myPIMS-EducationAdd'
                });
            },

            doInit : () => {
                bbs.page = 1;
                bbs.isMounted = true;
                bbs.doSearch();
            },

            doSearch : () => {
                if( bbs.isMounted == false ) return;
                let params = {
                    page : bbs.page,
                    rows : bbs.rows,

                    idx_hq : 0,
                    sdate  : bbs.sdate,
                    edate  : bbs.edate,
                    skind  : bbs.skind,
                    title  : bbs.stext,

                };
                axios.get('/rest/mypims/getEducationList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.isAuth = res.data.is_auth=='Y'?true:false;
                        
                        // 현재는 팀 구성이 되어있지 않으므로 스탭을 지정하여 체크하도록 변경, 3995:슈리, 19054:헤이즐
                        if( bbs.isAuth == false && store.state.pd_auth == false  ) {
                        //if( bbs.isAuth == false ){
                            Swal.fire({
                                title : '전사 교육 관리',
                                text  : '권한이 없습니다.'
                            }).then(() => {
                                router.go(-1);
                            });
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
        });

        onMounted(() => {
            // Mounted
            if( store.state.pd_auth != true && store.state.pd_auth == false ) {
                router.go(-1);
                Swal.fire({
                    title : '전사 교육 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            bbs.doInit();
        });

        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>